// Action constants
import {SET} from './constants';

const DEFAULT_STATE = {
  loadingAccident: false,
  accident: null,
  accidentParties: [],

  loadingNotes: false,
  notes: [],

  loadingReceipts: false,
  receipts: [],

  loadingPhotos: false,
  photos: [],

  loadingDriverStatement: false,
  driverStatement: null,

  loadingPoliceReport: false,
  policeReport: null,

  loadingSamsonTickets: false,
  samsonTickets: [],

  loadingManualStates: false,
  manualStates: [],
};

export default (state = DEFAULT_STATE, {type, data}) => {
  switch (type) {
    case SET: {
      return {
        ...state,
        ...data,
      };
    }

    default: {
      return state;
    }
  }
};
