import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {hide as hideModalAct} from './redux/actions';

// Api
import createApi from '@matthahn/sally-fn/lib/vehicleHold/api/create.api.vehicleHold';

// Attributes
import autoReleaseAttr from '../../attributes/auto_release.attribute.vehicleHold';
import dateEtcAttr from '../../attributes/date_etc.attribute.vehicleHold';
import holdTypeAttr from '../../attributes/hold_type.attribute.vehicleHold';
import reasonAttr from '../../attributes/reason.attribute.vehicleHold';
import vehicleAttr from '../../attributes/vehicle.attribute.vehicleHold';

// Components
import PutVehicleOnHoldModal from '../../components/PutVehicleOnHoldModal/PutVehicleOnHoldModal';

// Lib
import {lib} from '@matthahn/sally-ui';
import parseError from '../../../error/parseError';
import fkOrId from '@matthahn/sally-fw/lib/lib/fkOrId';

// Prep
import createPrep from '../../preparation/create.preparation.vehicleHold';

// Sockets
import putOnHoldSocket from '../../socket/putOnHold.socket.vehicleHold';
import removeFromHoldSocket from '../../socket/removeFromHold.socket.vehicleHold';

// Alert
const {alert} = lib;

class PutVehicleOnHoldContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    vehicle: PropTypes.object,
    dispatch: PropTypes.func,
  };

  static INITIAL_STATE = {
    loading: false,
    auto_release: autoReleaseAttr(''),
    date_etc: dateEtcAttr(''),
    hold_type: holdTypeAttr(''),
    reason: reasonAttr(''),
  };

  state = {
    ...this.constructor.INITIAL_STATE,
  };

  componentDidMount() {
    this.subscribe();
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  events = [];

  subscribe = () => {
    this.events = [
      putOnHoldSocket.subscribe(
        this.socketFired('Vehicle was put on hold by somebody else')
      ),
      removeFromHoldSocket.subscribe(
        this.socketFired('Vehicle was made available again by somebody else')
      ),
    ];
  };

  unsubscribe = () => {
    this.events.forEach((unsubscribe) => unsubscribe());
  };

  init = () => {
    this.setState({...this.constructor.INITIAL_STATE});
  };

  socketFired = (message) => (hold) => {
    const {vehicle, visible, dispatch} = this.props;
    const {loading} = this.state;
    if (loading || !visible || !vehicle || vehicle.id !== fkOrId(hold.vehicle))
      return;
    alert.info(message);
    dispatch(hideModalAct());
  };

  onClose = () => {
    if (this.state.loading) return;
    this.props.dispatch(hideModalAct());
  };

  onChange = (val, key) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  onSave = async () => {
    const {dispatch, vehicle} = this.props;
    const {auto_release, loading, hold_type, reason, date_etc} = this.state;
    if (loading) return;

    this.setState({loading: true});

    try {
      const hold = await createPrep({
        auto_release,
        hold_type,
        reason,
        date_etc,
        vehicle: vehicleAttr(vehicle.id),
      });
      await createApi(hold);
      alert.success('Vehicle is now on hold');
      dispatch(hideModalAct());
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
    }

    this.setState({loading: false});
  };

  render() {
    const {visible, vehicle} = this.props;
    const {auto_release, loading, hold_type, reason, date_etc} = this.state;
    return (
      <PutVehicleOnHoldModal
        auto_release={auto_release}
        date_etc={date_etc}
        hold_type={hold_type}
        loading={loading}
        onChange={this.onChange}
        onClose={this.onClose}
        onSave={this.onSave}
        reason={reason}
        vehicle={vehicle}
        visible={visible}
      />
    );
  }
}

export default connect((state) => ({...state.putVehicleOnHold}))(
  PutVehicleOnHoldContainer
);
