import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Button, Row, Column} from '@matthahn/sally-ui';
import StepFooter from '../../../layout/components/StepFooter/StepFooter';
import AttributeInput from '../../../components/components/atoms/AttributeInput/AttributeInput';

// Lib
import getTimeZoneFromBranch from '../../../libs/getTimeZoneFromBranch';

const CreateAccidentStep = ({
  Container,
  loading,
  branch,
  appointment_time,
  camera_unplugged,
  datetime_of_loss,
  description,
  dol_city,
  dol_location,
  dol_state,
  police_called,
  police_report_taken,
  rideshare_signed_in_period,
  rideshare_signed_in_service,
  tow_required,
  disabledDates,
  onChange,
  onCreate,
  onBack,
}) => (
  <Fragment>
    <Container>
      <Row margin>
        <Column>
          <AttributeInput
            value={datetime_of_loss}
            disabled={loading}
            onChange={onChange}
            disabledDates={disabledDates}
            timeZone={getTimeZoneFromBranch({branch})}
          >
            {datetime_of_loss.label.default}
          </AttributeInput>
        </Column>
      </Row>
      <Row margin>
        <Column size={1 / 4}>
          <AttributeInput
            value={police_called}
            disabled={loading}
            onChange={onChange}
          >
            {police_called.label.default}
          </AttributeInput>
        </Column>
        <Column size={1 / 4}>
          <AttributeInput
            value={police_report_taken}
            disabled={loading}
            onChange={onChange}
          >
            {police_report_taken.label.default}
          </AttributeInput>
        </Column>
        <Column size={1 / 4}>
          <AttributeInput
            value={camera_unplugged}
            disabled={loading}
            onChange={onChange}
          >
            {camera_unplugged.label.default}
          </AttributeInput>
        </Column>
        <Column size={1 / 4}>
          <AttributeInput
            value={tow_required}
            disabled={loading}
            onChange={onChange}
          >
            {tow_required.label.default}
          </AttributeInput>
        </Column>
      </Row>
      <Row margin>
        <Column>
          <AttributeInput
            value={description}
            disabled={loading}
            onChange={onChange}
          >
            {description.label.default}
          </AttributeInput>
        </Column>
      </Row>
      <Row margin>
        <Column size={1 / 2}>
          <AttributeInput
            value={dol_city}
            disabled={loading}
            onChange={onChange}
          >
            {dol_city.label.default}
          </AttributeInput>
        </Column>
        <Column size={1 / 2}>
          <AttributeInput
            value={dol_location}
            disabled={loading}
            onChange={onChange}
          >
            Street Name / Intersection
          </AttributeInput>
        </Column>
      </Row>
      <Row margin>
        <Column>
          <AttributeInput
            value={dol_state}
            disabled={loading}
            onChange={onChange}
          >
            {dol_state.label.default}
          </AttributeInput>
        </Column>
      </Row>
      <Row margin>
        <Column size={1 / 2}>
          <AttributeInput
            value={rideshare_signed_in_service}
            disabled={loading}
            onChange={onChange}
          >
            {rideshare_signed_in_service.label.default}
          </AttributeInput>
        </Column>
        <Column size={1 / 2}>
          <AttributeInput
            value={rideshare_signed_in_period}
            disabled={loading}
            onChange={onChange}
            notFilterable
          >
            {rideshare_signed_in_period.label.default}
          </AttributeInput>
        </Column>
      </Row>
      <Row>
        <Column>
          <AttributeInput
            value={appointment_time}
            disabled={loading}
            onChange={onChange}
            timeZone={getTimeZoneFromBranch({branch})}
          >
            {appointment_time.label.default}
          </AttributeInput>
        </Column>
      </Row>
    </Container>
    <Container>
      <StepFooter
        left={
          <Button size="small" theme="grey" onClick={onBack} disabled={loading}>
            Back
          </Button>
        }
        right={
          <Button size="small" onClick={onCreate} loading={loading}>
            Create
          </Button>
        }
      />
    </Container>
  </Fragment>
);

CreateAccidentStep.propTypes = {
  Container: PropTypes.any,
  loading: PropTypes.bool,
  branch: PropTypes.any,
  appointment_time: PropTypes.object,
  camera_unplugged: PropTypes.object,
  datetime_of_loss: PropTypes.object,
  description: PropTypes.object,
  dol_city: PropTypes.object,
  dol_location: PropTypes.object,
  dol_state: PropTypes.object,
  police_called: PropTypes.object,
  police_report_taken: PropTypes.object,
  rideshare_signed_in_period: PropTypes.object,
  rideshare_signed_in_service: PropTypes.object,
  tow_required: PropTypes.object,
  onChange: PropTypes.func,
  onCreate: PropTypes.func,
  onBack: PropTypes.func,
};

export default CreateAccidentStep;
