import {SET} from './constants';

export const set = (data = {}) => {
  return {
    type: SET,
    data,
  };
};

export const updateAccident = (newAccident) => (dispatch, getState) => {
  const oldAccident = getState().accident.accident;
  if (!newAccident.id || !oldAccident || newAccident.id !== oldAccident.id)
    return;
  const accident = !newAccident.parties_involved
    ? {...newAccident, parties_involved: oldAccident?.parties_involved}
    : {...newAccident};
  dispatch(set({accident}));
};
